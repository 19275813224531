import { computed, onMounted, ref, watch } from 'vue'
import Chart from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { TreemapController, TreemapElement } from 'chartjs-chart-treemap'
import { ChoroplethController, ProjectionScale, ColorScale, GeoFeature } from 'chartjs-chart-geo'

import 'chartjs-adapter-moment'
import { customLegendPlugin } from '../customLegendPlugin'

Chart.register(TreemapController, TreemapElement, ChoroplethController, ProjectionScale, ColorScale, GeoFeature)

/**
 * Create chart
 * @param type Type of chartjs
 * @param datasets Chartjs datasets array
 * @param labels Chartjs labels array
 * @param chartOptions Chartjs config chartOptions
 * @return {{canvas: ToRef<null>, chart}}
 */
export default function ({ type, datasets, labels, chartOptions }) {
  const canvas = ref(null)

  const config = computed(() => {
    return {
      type,
      data: {
        labels: labels.value,
        datasets: datasets.value,
      },
      options: chartOptions,
      plugins: [...(chartOptions.plugins?.datalabels ? [ChartDataLabels, customLegendPlugin] : [customLegendPlugin])],
    }
  })

  let chart

  const buildChart = () => {
    chart = new Chart(canvas.value, config.value)
    window.chart = chart
  }

  const update = () => {
    chart?.destroy()
    buildChart()
  }

  onMounted(index => {
    buildChart()
  })

  watch(config, update)

  return {
    chart,
    canvas,
  }
}
