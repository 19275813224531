export function balanceDataArrayForPieChart(data: { key: string; value: number }[]) {
  const sortedArr = data.sort((a, b) => b.value - a.value)
  const total = sortedArr.reduce((sum, d) => sum + d.value, 0)
  const n = sortedArr.length
  const smallAngleThreshold = 20
  let sumConsecutiveAngle = 0
  let i = 0
  let j = 0

  while (i < n) {
    const sliceAngle = (sortedArr[i].value / total) * 360
    sumConsecutiveAngle += sliceAngle

    if (sliceAngle >= smallAngleThreshold) {
      i++
      j++
      sumConsecutiveAngle = 0
      continue
    }

    i++
  }

  if (i - j <= 1) return { hasNarrowArea: false }

  while (sumConsecutiveAngle < 45) {
    const sliceAngle = (sortedArr[j - 1].value / total) * 360
    const tempSum = sumConsecutiveAngle + sliceAngle

    if (tempSum >= 90) {
      break
    }

    sumConsecutiveAngle = tempSum
    j--
  }

  const startIndex = j
  const endIndex = n - 1
  const sliceAtIndex = Math.ceil((endIndex - startIndex + 1) / 2) + startIndex
  const balancedData = [...sortedArr.slice(sliceAtIndex), ...sortedArr.slice(0, sliceAtIndex)]

  return { startIndex: j, endIndex: n - 1, hasNarrowArea: true, balancedData, sliceAtIndex }
}

/**
 *
 * @param x1 Start point
 * @param y1
 * @param x2 End point
 * @param y2
 * @param cx Circle center
 * @param cy
 * @param r  Circle radius
 * @returns
 */
export function getIntersectionPoint(
  x1: number,
  y1: number,
  x2: number,
  y2: number,
  cx: number,
  cy: number,
  r: number,
) {
  // Vector from start to end point
  const dx = x2 - x1
  const dy = y2 - y1

  // Length of vector
  const len = Math.sqrt(dx * dx + dy * dy)

  // Unit vector
  const ux = dx / len
  const uy = dy / len

  // Point on circle
  return [cx + ux * r, cy + uy * r]
}
