interface PivotTable {
  columns: string[] | string[][]
  rows: string[][]
  lablels: string[]
  data: any[][]
}
interface TableData {
  headers: string[] | string[][]
  rows: any[][]
}
interface TableOptions {
  display: 'compact' | 'outline' | 'tabular'
  showSubtotals: boolean
  showTotal: boolean
  columns: string[]
  striping: boolean | number
}
function transformTabular(input, options) {
  const columns = [
    {
      field: 'label',
      children: labels,
    },
    ...inputColumns,
  ]

  const resultData = []

  function traverseData(node, currentLabels, labelIndex) {
    if (node.values) {
      const labelObj = {}
      labels.forEach((label, index) => {
        if (currentLabels[index] !== undefined) {
          labelObj[label] = currentLabels[index]
        }
      })
      const entry = { label: labelObj }
      inputColumns.forEach((col, index) => {
        entry[col] = node.values[index]
      })
      resultData.push(entry)
    }

    if (typeof node === 'object' && !Array.isArray(node) && node !== null) {
      for (const key in node) {
        if (key !== 'values') {
          const newLabels = currentLabels.slice()
          newLabels[labelIndex] = key
          traverseData(node[key], newLabels, labelIndex + 1)
        }
      }
    }
  }

  traverseData(data, [], 0)

  return {
    columns: columns,
    data: resultData,
  }
}
function getNode(path, data) {
  return path.reduce((acc, key) => {
    if (!acc) return null
    return acc[key]
  }, data)
}
function getColumnsData(columns, labelIndex, row, data) {
  return columns.reduce((acc, column) => {
    const colName = column.field || column
    acc[colName] = getNode(row.slice(0, labelIndex + 1).concat(colName), data)
    return acc
  }, {})
}
function getTotal(data, columns) {
  if (data._total) {
    return columns.reduce((acc, column) => {
      const colName = column.field || column
      acc[colName] = data._total[colName]
      return acc
    }, {})
  }
}
// Input
// const input = {
//   columns: [{ field: 'date', children: ['2024-02', '2024-03'] }],
//   rows: [
//     ['benchmark', 'carbon-footprint'],
//     ['benchmark', 'carbon-intensity'],
//     ['fund', 'carbon-footprint'],
//     ['fund', 'carbon-intensity'],
//   ],
//   labels: ['metric', 'indicator'],
//   data: {
//     benchmark: {
//       'carbon-footprint': {
//         date: { '2024-02': -0.1, '2024-03': -0.1 },
//         _total: -0.2,
//       },
//       'carbon-intensity': {
//         date: { '2024-02': 0.2, '2024-03': 0.2 },
//         _total: 0.4,
//       },
//       _total: {
//         date: { '2024-02': 0.1, '2024-03': 0.1 },
//         _total: 0.2,
//       },
//     },
//     fund: {
//       'carbon-footprint': {
//         date: { '2024-02': 0.1, '2024-03': 0.1 },
//         _total: 0.2,
//       },
//       'carbon-intensity': {
//         date: { '2024-02': -0.2, '2024-03': -0.2 },
//         _total: -0.4,
//       },
//       _total: {
//         date: { '2024-02': -0.1, '2024-03': -0.1 },
//         _total: -0.2,
//       },
//     },
//     _total: {
//       date: { '2024-02': 0, '2024-03': 0 },
//       _total: 0,
//     },
//   },
// }

function transformOutline(input, options) {
  // Target
  // columns: ['metric', 'indicator', { field: 'date', children: ['2024-02', '2024-03'] }],
  // rows: [
  //   {
  //     _level: 0,
  //     metric: 'benchmark',
  //     date: {
  //       '2024-02': -0.1,
  //       '2024-03': 0.2,
  //     },
  //   },
  //   {
  //     _level: 1,
  //     indicator: 'carbon-footprint',
  //     date: {
  //       '2024-02': -0.1,
  //       '2024-03': -0.1,
  //     },
  //   },
  //   {
  //     _level: 1,
  //     indicator: 'carbon-intensity',
  //     date: {
  //       '2024-02': 0.2,
  //       '2024-03': 0.2,
  //     },
  //   },
  //   {
  //     _level: 0,
  //     metric: 'fund',
  //     date: {
  //       '2024-02': 0.1,
  //       '2024-03': -0.2,
  //     },
  //   },
  //   {
  //     _level: 1,
  //     indicator: 'carbon-footprint',
  //     date: {
  //       '2024-02': 0.1,
  //       '2024-03': 0.1,
  //     },
  //   },
  //   {
  //     _level: 1,
  //     indicator: 'carbon-intensity',
  //     date: {
  //       '2024-02': -0.2,
  //       '2024-03': -0.2,
  //     },
  //   },
  // ],
  const { columns, rows, labels, data } = input

  const output = {
    columns: [...labels, ...columns],
    data: [],
  }
  const parentRows: string[] = []
  rows.forEach((row, rowIndex) => {
    row.forEach((label, labelIndex) => {
      if (labelIndex === row.length - 1) {
        output.data.push({
          _level: labelIndex,
          [labels[labelIndex]]: label,
          ...getColumnsData(columns, labelIndex, row, data),
        })
      } else if (!parentRows.includes(label)) {
        parentRows.push(label)
        const outputRow = {
          _level: labelIndex,
          [labels[labelIndex]]: label,
        }
        if (options.showSubtotals) {
          columns.forEach(column => {
            const colName = column.field || column
            outputRow[colName] = getNode(row.slice(0, labelIndex + 1).concat(['_total', colName]), data)
          })
        }
        output.data.push(outputRow)
      }
    })
  })
  if (options.showTotal) {
    const totalValues = getTotal(data, columns)
    if (totalValues) {
      output.data.push({
        _level: 0,
        [labels[0]]: 'total',
        ...totalValues,
      })
    }
  }
  return output
}
function transformCompact(input, options) {
  const { columns, rows, data } = input

  const output = {
    columns: ['label', ...columns],
    data: [],
  }
  const parentRows: string[] = []
  rows.forEach((row, rowIndex) => {
    row.forEach((label, labelIndex) => {
      if (!label) return
      if (labelIndex === row.length - 1) {
        output.data.push({
          _level: labelIndex,
          label,
          ...columns.reduce((acc, column) => {
            const colName = column.field || column
            acc[colName] = getNode(row.slice(0, labelIndex + 1).concat(colName), data)
            return acc
          }, {}),
        })
      } else if (!parentRows.includes(label)) {
        parentRows.push(label)
        const outputRow = {
          _level: labelIndex,
          label,
        }
        if (options.showSubtotals) {
          columns.forEach(column => {
            const colName = column.field || column
            outputRow[colName] = getNode(row.slice(0, labelIndex + 1).concat(['_total', colName]), data)
            console.log(row.slice(0, labelIndex + 1).concat([colName, '_total']), data)
          })
        }
        output.data.push(outputRow)
      }
    })
  })
  if (options.showTotal) {
    const totalValues = getTotal(data, columns)
    if (totalValues) {
      output.data.push({
        _level: 0,
        label: 'total',
        ...totalValues,
      })
    }
  }
  // row.slice(1).forEach((col, colIndex) => {
  //   rowObj[columns[colIndex]] = data[row[0]][row[col]]
  // })
  // return rowObj
  // debugger
  return output
}
// function getColumns(columns) {
//   const mappedColumns = columns.reduce((acc, column) => {
//     if (Array.isArray(column)) {
//       const [parent, child] = column
//       acc[parent] = acc[parent] || []
//       acc[parent].push(child)
//     } else {
//       acc[column] = []
//     }
//     return acc
//   }, {})
//   return Object.entries(mappedColumns).map(([field, children]) => {
//     if (children.length) {
//       return {
//         field,
//         children,
//       }
//     }
//     return { field }
//   })
// }
export function transformData(input: any, options: TableOptions) {
  const { display } = options

  if (display === 'outline') {
    return transformOutline(input, options)
  } else if (display === 'tabular') {
    return transformTabular(input, options)
  } else if (display === 'compact') {
    return transformCompact(input, options)
  }
  return transformOutline(input, options)
}

function addStriping(data, striping: string | number) {
  if (typeof striping === 'string') {
    let currentLabel = data[0][striping]
    let _striped = true
    return data.map((d: any) => {
      if (d[striping] && d[striping] !== currentLabel) {
        _striped = !_striped
        currentLabel = d[striping]
      }
      return {
        ...d,
        _striped,
      }
    })
  }
  if (typeof striping === 'number') {
    let currentLabel: string
    let _striped = false
    return data.map((d: any) => {
      if (d._level === striping && d.label !== currentLabel) {
        _striped = !_striped
        currentLabel = d.label
      }
      return {
        ...d,
        _striped,
      }
    })
  }
  return data
}
function filterColumns(data: any, columns, labels: string[]) {
  // If some labels are filtered out, remove the lines corresponding to those labels
  labels.forEach((label, labelIndex) => {
    if (!columns.includes(label)) {
      data = data.filter(d => d._level !== labelIndex)
    }
  })
  return data
}
export function transformTable(input: any, options: TableOptions) {
  const tableData = transformData(input, options)
  const { striping, columns, display } = options
  if (striping) {
    if (display === 'compact') {
      tableData.data = addStriping(tableData.data, input.labels.indexOf(striping))
    } else {
      tableData.data = addStriping(tableData.data, striping)
    }
  }
  if (columns) {
    tableData.columns = columns
    if (display !== 'compact') {
      tableData.data = filterColumns(tableData.data, columns, input.labels)
    }
  }
  return tableData
}
