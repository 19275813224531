import {
  IGridColumnMetadata,
  IGridColumnsMetadata,
  IGridHeader,
  IGridHeaderState,
  IGridItemData,
  IGridStateLimited,
  useHashList,
  useHeader,
  useHeaderList,
  useSortList,
} from '@hauru/common'
import { reactive, readonly } from 'vue'
import { reactiveComputed } from '@vueuse/shared'

export interface IGridColumnsArgs {
  /**
   * TODO: Move to theme ?
   * The default width of each column in px
   */
  columnWidth: number
  /**
   * TODO: Move to theme ?
   * The minimum width of each column in px
   */
  columnMinWidth: number
  /**
   * The number of columns to freeze on the left side
   */
  freezedColumnsCount: number
  /**
   * Boolean indicating whether to show the id column
   */
  excludeIdColumn: boolean
  /**
   * List of columns to completely ignore during the rendering of the grid
   */
  excludeColumns: string[]
  sortDefault: boolean
  idColumn: string
  indexByVisibility: boolean
}

export interface IGridStoredColumn {
  index: number
  label: string
  labelPath: string
  width: number
  widthPercent: number
  show: boolean
  children: IGridStoredColumn[]
}

export type IGridColumnsState = ReturnType<typeof useColumns>
export function useColumns({
  freezedColumnsCount = 0,
  columnWidth = 120,
  columnMinWidth = 60,
  excludeIdColumn = true,
  excludeColumns = [] as string[],
  sortDefault = false,
  idColumn = 'id',
  indexByVisibility = true,
}: Partial<IGridColumnsArgs> = {}) {
  const headerList = useHeaderList()
  let state: IGridStateLimited | undefined

  const columns = reactive({
    headers: headerList,
    /**
     * Default column header
     */
    defaultColumn: 'default',
    /**
     * Depth of the column header tree
     */
    depth: 0,
    mark: 0,
    get,
    is,
    updateColumns,
    updateWidths,
    yieldColumns,
    yieldColumnsLevel,
    showAll: () => toggleAll(true),
    hideAll: () => toggleAll(false),
    setState: (s: IGridStateLimited) => (state = s),
    all: [] as IGridHeader[],
    freezed: [] as IGridHeader[],
    visibleAll: [] as IGridHeader[],
    visibleFreezed: [] as IGridHeader[],
    visibleUnfreezed: [] as IGridHeader[],
    /**
     * The number of columns to freeze on the left side
     */
    freezedCount: freezedColumnsCount,
    /**
     * The total width of visible freezed columns in px
     */
    visibleFreezedWidth: { value: 0 },
    setFreezedCount: (value: number) => (columns.freezedCount = value),
    sortBy: useSortList(),
    groupBy: useHashList(),
    /**
     * The default width of columns in px
     */
    width: columnWidth,
    setWidth: (value: number) => (columns.width = value),
    /**
     * The minimum width of each column in px
     */
    widthMin: columnMinWidth,
    setWidthMin: (value: number) => (columns.widthMin = value),
    /**
     * Boolean indicating whether to show the id column
     */
    excludeId: excludeIdColumn,
    /**
     * Toggles the exlude id property. If a value is provided, the show property is set to that value.
     * @param value - The value to set the exclude id property to
     */
    toggleExcludeId(value?: boolean) {
      columns.excludeId = value ?? !columns.excludeId
    },
    /**
     * List of columns to completely ignore during the rendering of the grid
     */
    exclude: new Set(excludeColumns),
    /**
     * Id column
     */
    idColumn,
    trackResize: 0,
    /**
     * Registers a resize event, in order to trigger associated effects
     */
    registerResize: () => {
      columns.trackResize = (columns.trackResize + 1) % 1024
    },
    calculateColumns,
    expandAll,
    restoreColumns,
  })

  columns.all = reactiveComputed(() => {
    recalculateIndexes()
    return [...columns.yieldColumns({ includeHidden: true })]
  })
  columns.freezed = reactiveComputed(() => columns.all.slice(0, columns.freezedCount))
  columns.visibleAll = reactiveComputed(() => [...columns.yieldColumns()])
  columns.visibleFreezed = reactiveComputed(() => columns.freezed.filter(h => h?.show))
  columns.visibleUnfreezed = reactiveComputed(() => columns.visibleAll.slice(columns.visibleFreezed.length))
  columns.visibleFreezedWidth = reactiveComputed(() => ({
    value: columns.visibleFreezed.reduce((acc, cur) => acc + cur.width, 0),
  }))

  /**
   * This function recalculates the index, level, and index path for each header in a grid.
   * This function is called when the structure of the grid changes, to ensure the properties stay updated.
   */
  function recalculateIndexes() {
    let index = 0
    recalculate()

    function recalculate(headerList = columns.headers.list, level = 0, indexPath: number[] = []) {
      let relativeIndex = 0
      for (const header of headerList) {
        header.setIndex(
          header.visibleOrDefaultCollapsed.length > 0 || (!header.show && indexByVisibility) ? index : index++,
        )
        header.setLevel(level)
        header.setIndexPath([...indexPath, relativeIndex])
        recalculate(header.visibleOrDefaultCollapsed, level + 1, [...indexPath, relativeIndex])
        relativeIndex++
      }
    }
  }

  function updateMark() {
    columns.mark = (columns.mark + 1) % 100
  }

  /**
   * workaround to reorder the columns
   */
  function sort(headers = columns.headers) {
    const compareFn = (a: IGridHeaderState, b: IGridHeaderState) => {
      a.children.sort(compareFn)
      return a.label.localeCompare(b.label)
    }
    for (const header of headers.list) {
      header.sort(compareFn)
    }
  }

  /**
   * This function iterates through a list of headers in a grid, and removes the headers which are not marked.
   * This function is used for cleaning up the grid, by removing unused or outdated headers.
   *
   * @param headers - The list of headers to check. If no list is passed, the function uses the main headers list from the columns object.
   */
  function removeUnmarked(headers = columns.headers) {
    const list = new Array(...headers.list)
    for (const header of list) {
      if (header.mark !== columns.mark) {
        headers.remove(header.label)
      } else removeUnmarked(header.children)
    }
  }

  /**
   * Returns the column header indicated by the path of labels
   * @param keys Path of column labels leading to the desired column header
   */
  function get(keys: (string | number | symbol)[]) {
    let childHeaders = headerList as typeof headerList | undefined
    for (const key of keys.slice(0, -1)) childHeaders = childHeaders?.get(key)?.children
    return childHeaders?.get(keys.at(-1)!)
  }

  /**
   * Returns a boolean that indicates whether a column header exists
   * @param keys Path of column labels leading to the desired column header
   */
  function is(keys: (string | number | symbol)[]) {
    return !!get(keys)
  }

  /**
   * Updates the width percent of each header in a headers list.
   *
   * @param headers - The headers list that need its headers' width percentages updated.
   */
  function updateWidthPercents(headers = columns.headers) {
    const totalWidth = headers.list.reduce((total, header) => total + header.width, 0)
    headers.list.forEach(header => {
      headers.get(header.label)?.setWidthPercent(header.width / totalWidth)
    })
  }

  /**
   * This function updates the width of each header in a headers list based on its width percent and the total width provided.
   * If headers is not provided, it defaults to headerList.
   *
   * @param totalWidth - The total width that should be distributed among the headers.
   * @param headers - The headers list whose headers' widths need to be updated.
   */
  function updateWidths(totalWidth: number, headers = headerList) {
    let countWidths = 0
    headers.list.slice(0, -1).forEach(header => {
      countWidths += headers.get(header.label)!.setWidth(Math.floor(totalWidth * header.widthPercent))
    })
    if (headers.list.length) headers.get(headers.list.at(-1)!.label)!.setWidth(totalWidth - countWidths)
  }

  function getDefaultColumns(header?: IGridHeader, metadata?: IGridColumnMetadata) {
    const defaultColumns = metadata?.default_columns?.filter(col => header?.children?.list?.find(c => c.label === col))
    if (defaultColumns?.length) return defaultColumns
    return header?.children.is(columns.defaultColumn) ? [columns.defaultColumn] : []
  }

  /**
   * Calculates the total number of columns and the maximum depth for a given headers list. Additionally, this function updates
   * each header's column count in the headers list based on its children.
   *
   * @param headers - The headers list for which to calculate columns and depth.
   * @param metadata - An optional object mapping labels to metadata objects. If provided, the metadata for a header will be used when calculating its column count and depth.
   */
  function calculateColumns(headers = columns.headers.list, metadata?: IGridColumnsMetadata, level = 0) {
    let columnCount = 0
    let depth = level + 1

    headers.forEach(header => {
      header.recalculateVisible()

      const [curColumnCount, curDepth] = header.visible.length
        ? calculateColumns(header.visible, metadata?.[header.label].children, level + 1)
        : [1, depth]
      header.setColumnCount(curColumnCount)

      columnCount += curColumnCount
      depth = Math.max(depth, curDepth)
    })

    if (level === 0) columns.depth = depth
    return [columnCount, depth]
  }

  /**
   * Adds or updates headers in the headers list based on the provided data.
   * It also calculates and returns the depth of the added or updated data structure.
   *
   * @param headers - The headers list that needs headers to be added or updated.
   * @param data - The data that determines which headers need to be added or updated in the headers list.
   * @param metadata - An optional object mapping labels to metadata objects. If provided, the metadata for a header will be used when adding or updating it in the headers list.
   * @param newWidth - The width to set for any new headers added to the headers list.
   * @param labels - An optional array of labels to be associated with the data.
   */
  function addOrUpdate(
    headers: typeof headerList,
    data: IGridItemData,
    metadata: IGridColumnsMetadata | undefined,
    newWidth: number,
    labels: string[] = [],
    level = 0,
    parentHeader?: IGridHeader,
  ) {
    for (const key of Object.keys(data || {})) {
      // Ignore the id column and the columns that are excluded
      if ((columns?.excludeId && key === idColumn) || columns?.exclude?.has(key)) continue

      const header = headers.get(key)
      const columnMetadata = metadata?.[key]
      if (header) {
        header.setMark(columns.mark)
      } else {
        headers.add(
          useHeader(
            state!,
            columns,
            metadata,
            parentHeader,
            columnMetadata?.width ?? newWidth,
            key,
            labels,
            columns.mark,
          ),
        )
      }
      if (data[key] instanceof Object) {
        addOrUpdate(
          headers.get(key)!.children,
          data[key] as IGridItemData,
          columnMetadata?.children,
          newWidth,
          [...labels, key],
          level + 1,
          headers.get(key),
        )
        // For each default column set the showWhenExpanded and showWhenCollapsed properties
        const defaultColumns = getDefaultColumns(headers.get(key), metadata?.[key])
        headers.get(key)?.setCollapsedColumns(defaultColumns)
        defaultColumns.forEach(col => {
          const child = headers.get(key)!.children.get(col)
          if (!child) return

          child.toggleShowWhenExpanded(false, false) // metadata?.[key]?.show_default_columns ?? false
          child.toggleShowWhenCollapsed(true, false)
        })
      }
    }
    updateWidthPercents(headers)
    if (level === 0) columns.calculateColumns()
  }

  /**
   * Updates the columns in a grid. It adds or updates headers based on the data in the grid's state, removes any unmarked headers,
   * sorts the headers if sortDefault is true, and updates the depth of the columns.
   */
  function updateColumns() {
    if (!state) return
    updateMark()
    addOrUpdate(headerList, state.data[0] ?? state.metadata?.columnsKeys ?? {}, state.metadata.columns, columns.width)
    removeUnmarked()
    if (sortDefault) sort()
    calculateColumns()
  }

  /**
   * Generator function that yields all visible headers
   *
   * @param options.includeHidden - Whether or not to include hidden headers
   * @param headers - The headers list from which to yield headers.
   */
  function* yieldColumns({ includeHidden = false } = {}, headers = columns.headers.list): Generator<IGridHeader> {
    for (const header of headers) {
      if (!includeHidden && !header.show) continue

      if (header.visibleOrDefaultCollapsed.length) {
        yield* yieldColumns({ includeHidden }, header.visibleOrDefaultCollapsed)
      } else {
        yield header as IGridHeader
      }
    }
  }

  /**
   * Generator function that yields headers from a given headers list at a specific level.
   *
   * @param level - The level at which to yield headers from the headers list.
   * @param from - The starting index from which to yield headers. If not provided, headers will be yielded from the start.
   * @param to - The ending index up to which to yield headers. If not provided, headers will be yielded until the end.
   * @param headers - The headers list from which to yield headers.
   */
  function* yieldColumnsLevel(
    level: number,
    from?: number,
    to?: number,
    headers = headerList.list,
    curLevel = 0,
    curIndex = 0,
  ): Generator<IGridHeader> {
    for (const header of headers) {
      if (to !== undefined && curIndex >= to) return

      if (!header.show) continue

      if (curLevel === level) {
        if (from === undefined || curIndex + header.columnCount - 1 >= from) yield header as IGridHeader
      } else if (curLevel < level && header.visible.length) {
        yield* yieldColumnsLevel(level, from, to, header.visible, curLevel + 1, curIndex)
      }
      curIndex += header.columnCount
    }
  }

  function toggleAll(show: boolean, headers = columns.headers) {
    for (const header of headers.list) {
      header.toggleShow(show, false)
      toggleAll(show, header.children)
    }
    columns.calculateColumns()
  }

  function expandAll(headers = columns.headers) {
    for (const header of headers.list) {
      if (!header.isExpanded) header.toggleExpanded()
    }
  }

  function restoreColumns(storedColumns: IGridStoredColumn[], headers = columns.headers) {
    for (const storedHeader of storedColumns) {
      const header = headers.get(storedHeader.label)
      if (header) {
        const { index, width, widthPercent, show, isExpanded, labelPath, children } = storedHeader
        const headerIndex = headers.list.findIndex(header => header.labelPath === labelPath)
        header.setWidth(width)
        header.setWidthPercent(widthPercent)
        header.toggleShow(show, false)
        header.toggleExpanded(isExpanded)
        headers.move(headerIndex, index)
        if (header.children.list.length) restoreColumns(children, header.children)
      }
    }
  }

  return readonly(columns)
}
