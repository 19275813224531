<script setup lang="ts">
// @ts-nocheck
import { computed, ref, watch } from 'vue'
import { IGridState, useGridAPI } from '@hauru/common'
const props = defineProps<{
  data: { id: string; type: string; name: string; EMV: number }[]
  options: { state: IGridState }
}>()
const tdata = computed(() => {
  const currentGroupBy = props.options.state.finder.parentNode
  if (!props.options.state.metadata.initialMetadata.tree) {
    if (!currentGroupBy) return []
    const tree = buildTree(
      props.options.state.metadata.initialData,
      currentGroupBy,
      props.options.state.finder.nodeLabel,
    )
    const flatTree = flat(tree)
    return generateFlatTreeData(flatTree, props.options.state.dataMap.map)
  } else {
    const flatTree = flat(props.options.state.metadata.initialMetadata.tree)
    return generateFlatTreeData(flatTree, props.options.state.dataMap.map)
  }
})
function generateFlatTreeData(flatTree, dataMap) {
  const names = {}
  const data = flatTree.map(item => {
    const id = item.map(v => v.row_id).join('.')
    const lastId = item.at(-1).row_id
    const name = item.at(-2) ? item.at(-2).label || item.at(-2).name : item.at(-1).label || item.at(-1).name
    const row = dataMap[lastId]
    const x = { ...row, id }
    Object.defineProperty(x, '_name', { value: name, enumerable: false })
    names[lastId] = x
    return x
  })
  Object.defineProperty(data, '_names', { value: names, enumerable: false })
  return data
}

function buildTree(data, columnName, nodeLabel) {
  const root = { row_id: -1, name: '', nodes: [] }
  const nodeMap = new Map()
  const groupedData = data.group(columnName)
  const dataMap = data.group('id')
  Object.keys(groupedData).map(key => {
    const node = {
      name: key,
      nodes: [],
      groupIds: groupedData[key].map(row => row.id),
    }
    nodeMap.set(key, node)
    return node
  })

  for (const [_, node] of nodeMap) {
    node.groupIds.forEach(id => {
      const row = dataMap[id].pop()
      if (row) {
        let childFound = false
        Object.entries(row).forEach(([key, value]) => {
          if (childFound) return
          if (value !== node.name && key !== columnName && nodeMap.has(value)) {
            const childNode = nodeMap.get(value)
            if (childNode.hasParent) return
            childNode.row_id = id
            childNode.item = row
            childNode.hasParent = true
            childFound = true
            if (nodeLabel) node.label = row[nodeLabel]
            node.nodes.push(childNode)
          }
        })
        if (!childFound) {
          const childNode = { row_id: id, name: row[columnName], nodes: [] }
          if (nodeLabel) node.label = row[nodeLabel]
          node.nodes.push(childNode)
        }
      }
    })
  }

  for (const [_, node] of nodeMap) {
    if (!node.hasParent) root.nodes = [...root.nodes, ...node.nodes]
  }

  return root
}

function flat(node, path = [], acc = []) {
  if (path.length) acc.push(path)
  node.nodes?.forEach(child => flat(child, path.concat(child), acc))
  return acc
}
const path = computed(() => (props.options.state.finder.path || '') as string)
const hidden = ref({} as Record<number, boolean>)
const bindings = ref<{ state: IGridState; options: any }[]>([])
const createBindings = columns => {
  return columns.map((column, level) => {
    const type = level === 0 ? '' : column[0]?._name
    const data = column.map(v => ({ ...v, '>': tdata.value.some(x => x.id.startsWith(v.id + '.')) ? '>' : '' }))
    const prevState = props.options.state
    const options = {
      actions: [],
      showCheckbox: false,
      columnMinWidth: 12,
      freezedColumnsCount: 0,
      autoexpandColumns: false,
      type,
      level,
      showHeaderMenu: prevState.showHeaderMenu,
      autosizeColumn: prevState.autosizeColumn,
    }
    const state = useGridAPI(options)
    state.setGrid({ data, metadata: { columns: prevState.metadata.columns } })
    state.columns.restoreColumns(prevState.columns.headers.list)
    state.columns.headers.list.at(-1).setWidth(12)
    state.columns.headers.list.at(-1).setAutoSized(true)
    state.autoSizeColumns()
    const parts = path.value?.split('.')
    if (parts?.length >= level) {
      state.selection.items.deselectAll()
      state.selection.items.toggleSelect(parts.slice(0, level + 1).join('.') as any, true)
    }
    watch(
      () => state.selection.items.list,
      () => {
        setTimeout(() => {
          if (!state.ref?.contains(document.activeElement)) return
          const selection = state.selection.items.list.map(v => state.dataMap.map[v]).filter(v => v)
          const last = selection.at(-1) as any
          let newPath = last?.id
          if (!last) {
            newPath = path.value.split('.').slice(0, level).join('.')
            return props.options.state.setFinder({ ...props.options.state.finder, path: newPath })
          }
          if (path.value === last.id) return
          props.options.state.setFinder({ ...props.options.state.finder, path: newPath })
        })
      },
      { deep: true },
    )
    setTimeout(() => {
      µ('.overflow-auto.gap-4')?.scroll(10000, 0)
    }, 100)
    return { state, options }
  })
}

const convertIdToLabel = id => {
  const { nodeLabel, parentNode } = props.options.state.finder
  const parts = id.split('.')
  return parts
    .map(part => {
      const label = nodeLabel ?? parentNode ?? '_name'
      return tdata.value._names[part]?.[label]
    })
    .join(' / ')
}
watch([() => props.options.state.finder.parentNode, () => props.options.state.finder.nodeLabel], () => {
  props.options.state.setFinder({ ...props.options.state.finder, path: null })
})
watch(
  [
    () => JSON.stringify(props.data),
    () => JSON.stringify(props.options.state.metadata.initialData),
    () => JSON.stringify(props.options.state.columns.headers.list.filter(col => col.show)),
    () => props.options.state.find,
    () => props.options.state.finder.parentNode,
    () => props.options.state.finder.nodeLabel,
  ],
  () => {
    if (props.options.state.find) {
      const regex = new RegExp(props.options.state.find, 'ig')
      const filteredData = tdata.value
        .filter(v => Object.values(v).some(v => regex.test(v)))
        .map(v => ({
          ...v,
          Path: convertIdToLabel(v.id),
          '>': tdata.value.some(x => x.id.startsWith(v.id + '.')) ? '>' : '',
        }))
      const type = 'SEARCH'
      const level = 0
      const prevState = props.options.state
      const options = {
        actions: [],
        showCheckbox: false,
        columnMinWidth: 12,
        freezedColumnsCount: 0,
        autoexpandColumns: false,
        type,
        level,
        showHeaderMenu: prevState.showHeaderMenu,
        autosizeColumn: props.options.state.autosizeColumn,
      }
      const state = useGridAPI(options)
      state.setGrid({ data: filteredData, metadata: { columns: prevState.metadata.columns } })
      state.columns.restoreColumns(prevState.columns.headers.list)
      state.columns.headers.list.at(-2)?.setWidth(400)
      state.columns.headers.list.at(-2)?.setAutoSized(true)
      state.columns.headers.move(state.columns.headers.list.length - 2, 0)
      state.columns.headers.list.at(-1)?.setWidth(12)
      state.columns.headers.list.at(-1)?.setAutoSized(true)
      if (!state.columns.headers.list.length) {
        state.columns.headers.replace(prevState.columns.headers.list)
      }
      watch(
        () => state.selection.items.list,
        () => {
          setTimeout(() => {
            props.options.state.setFind('')
            const selection = state.selection.items.list.map(v => state.dataMap.map[v]).filter(v => v)
            const last = selection.at(-1) as any
            let newPath = last?.id
            if (!last) {
              newPath = path.value.split('.').slice(0, level).join('.')
              return props.options.state.setFinder({ ...props.options.state.finder, path: newPath })
            }
            if (path.value === last.id) return
            props.options.state.setFinder({ ...props.options.state.finder, path: newPath })
          })
        },
        { deep: true },
      )
      bindings.value = [{ state, options }]
      return
    }
    const parts = path.value.split('.')
    const filteredData = tdata.value.filter(v =>
      v.id
        .split('.')
        .slice(0, -1)
        .every((part: string, index: number) => hidden.value[index] || part === parts[index]),
    )
    const columns = Object.values(filteredData.group(v => v.id.split('.').length))
    bindings.value = createBindings(columns)
  },
  { immediate: true },
)
watch(
  () => path.value,
  () => {
    if (path.value === '') {
      bindings.value.length = 1
      return
    }
    const bindingsLength = bindings.value.length
    const parts = path.value.split('.')
    const filteredData = tdata.value.filter(v =>
      v.id
        .split('.')
        .slice(0, -1)
        .every((part: string, index: number) => hidden.value[index] || part === parts[index]),
    )
    const columns = Object.values(filteredData.group(v => v.id.split('.').length))
    const newBindings = createBindings(columns)
    if (newBindings.at(-1).options.type === bindings.value.at(-1).options.type) return
    if (newBindings.length < bindingsLength)
      return bindings.value.splice(newBindings.length - 1, bindingsLength, newBindings[newBindings.length - 1])
    if (newBindings.length > bindingsLength) return bindings.value.push(newBindings[newBindings.length - 1])
    return bindings.value.splice(-1, 1, newBindings[newBindings.length - 1])
  },
)
</script>
<template>
  <div class="finder h-full">
    <div class="flex h-full gap-4 overflow-auto">
      <template v-for="binding in bindings" :key="binding.options.level">
        <div class="i-carbon/chevron-right -mx-2 -mt-px h-7 shrink-0 text-gray-700" v-if="binding.options.level"></div>
        <div
          v-if="hidden[binding.options.level]"
          class="i-carbon/right-panel-close -mx-2 -mt-px h-7 shrink-0 cursor-pointer"
          :tooltip="`OPEN ${binding.options.type}`"
          @click="hidden[binding.options.level] = false"
        ></div>
        <div
          v-else
          :style="{ width: binding.state.columns.headers.list.reduce((acc, v) => acc + v.width * v.show, 0) + 'px' }"
        >
          <div
            :class="props.options.state.gridTheme.finder.title.container"
            @click="
              props.options.state.setFinder({
                ...props.options.state.finder,
                path: path.split('.').slice(0, binding.options.level).join('.'),
              })
            "
          >
            <div class="m-auto truncate text-md font-semibold">{{ binding.options.type }}</div>
            <div
              class="i-carbon/right-panel-open invisible group-hover:visible"
              :class="binding.options.level === 0 && 'pointer-events-none'"
              :tooltip="`CLOSE ${binding.options.type}`"
              @click.stop="hidden[binding.options.level] = true"
              v-if="binding.options.level"
            ></div>
          </div>
          <nx-grid class="max-h-[calc(100%-1.75rem)] overflow-auto" :state="binding.state">
            <template v-for="name in Object.keys($slots)" #[name]="scope">
              <slot :name="name" v-bind="scope" />
            </template>
            <template #header="{ header }">
              <div v-if="header.label === '>'"></div>
            </template>
            <template #cell="{ cell, row, header, view }">
              <div nx-cell class="truncate px-2 tabular-nums" v-if="header.label === 'Path'">
                <div class="flex gap-1 truncate text-xs">
                  <div
                    class="flex gap-1"
                    :class="i === 0 || i === cell.split('.').length - 2 ? '' : 'min-w-[12px] truncate'"
                    v-for="(id, i) in cell.split('.')"
                  >
                    <template v-if="cell.split('.').length !== i + 1">
                      <div class="h-2 w-2 font-bold">{{ i + 1 }}</div>
                      <div class="truncate">{{ tdata._names[id]._name }}</div>
                      <div
                        class="i-carbon/chevron-right my-auto ml-auto shrink-0"
                        v-if="cell.split('.').length !== i + 2"
                      ></div>
                    </template>
                  </div>
                </div>
              </div>
              <div
                nx-cell
                class="my-auto ml-auto"
                :class="cell && 'i-carbon/chevron-right'"
                v-if="header.label === '>'"
              ></div>
            </template>
          </nx-grid>
        </div>
      </template>
    </div>
  </div>
</template>

<!-- HACK: not sure why these 2 icon do not load in hauru. -->
<!-- prettier-ignore -->
<style>
.i-carbon\/right-panel-open {
  -webkit-mask: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M2%206v20a2%202%200%200%200%202%202h24a2%202%200%200%200%202-2V6a2%202%200%200%200-2-2H4a2%202%200%200%200-2%202m20%200h6v20h-6zM4%206h16v9H9.83l3.58-3.59L12%2010l-6%206l6%206l1.41-1.41L9.83%2017H20v9H4z%22%2F%3E%3C%2Fsvg%3E) 0 0 / 100% 100%;
  mask: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M2%206v20a2%202%200%200%200%202%202h24a2%202%200%200%200%202-2V6a2%202%200%200%200-2-2H4a2%202%200%200%200-2%202m20%200h6v20h-6zM4%206h16v9H9.83l3.58-3.59L12%2010l-6%206l6%206l1.41-1.41L9.83%2017H20v9H4z%22%2F%3E%3C%2Fsvg%3E) 0 0 / 100% 100%;
}
.i-carbon\/right-panel-close {
  -webkit-mask: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M2%206v20a2%202%200%200%200%202%202h24a2%202%200%200%200%202-2V6a2%202%200%200%200-2-2H4a2%202%200%200%200-2%202m20%200h6v20h-6zM4%206h16v20H4v-9h10.17l-3.58%203.59L12%2022l6-6l-6-6l-1.41%201.41L14.17%2015H4z%22%2F%3E%3C%2Fsvg%3E) 0 0 / 100% 100%;
  mask: url(data:image/svg+xml;utf8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20viewBox%3D%220%200%2032%2032%22%3E%3Cpath%20fill%3D%22currentColor%22%20d%3D%22M2%206v20a2%202%200%200%200%202%202h24a2%202%200%200%200%202-2V6a2%202%200%200%200-2-2H4a2%202%200%200%200-2%202m20%200h6v20h-6zM4%206h16v20H4v-9h10.17l-3.58%203.59L12%2022l6-6l-6-6l-1.41%201.41L14.17%2015H4z%22%2F%3E%3C%2Fsvg%3E) 0 0 / 100% 100%;
}
.i-carbon\/right-panel-close, .i-carbon\/right-panel-open {
  background: currentColor;
  display: inline-block;
  height: 1em;
  width: 1em;
}
</style>
