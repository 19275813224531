import insane from 'insane'
window.insane = insane
window.tooltip = document.createElement('div')
tooltip.id = 'tooltip'
document.body.appendChild(tooltip)

function hasTTWarningOrError(element) {
  const warningOrErrorElement = element.closest("[status='error']") || element.closest("[status='warning']")
  if (warningOrErrorElement?.classList?.contains('error') || warningOrErrorElement?.classList?.contains('warning'))
    return warningOrErrorElement
  return null
}

addEventListener('mouseover', e => {
  if (!e.target) return
  if (tooltip.target && !document.contains(tooltip.target)) tooltip.target.onmouseleave()
  const targetWithTT = e.target.closest('[tt],[no-tt]')
  const checkWarningOrError = hasTTWarningOrError(e.target)
  const text =
    checkWarningOrError?.getAttribute('tt') || targetWithTT?.getAttribute('tt') || e.target.innerText || e.target.value
  if (targetWithTT?.getAttribute('no-tt') || !text || text.includes('\n')) return
  if (!targetWithTT?.getAttribute('tt')) return
  let top = targetWithTT.getBoundingClientRect().top + (document.documentElement.scrollTop || 0) - 2
  let left = targetWithTT.getBoundingClientRect().left - 8
  if (checkWarningOrError) {
    const color = checkWarningOrError.classList.contains('error') ? '#D70549' : '#EC5800'
    if (checkWarningOrError.classList.contains('tooltip-down')) {
      tooltip.classList.add('down')
      top = top + targetWithTT.getBoundingClientRect().height + 48
      left = targetWithTT.getBoundingClientRect().left - 48
    }
    tooltip.style = `visibility:hidden;--ttBackgroundColor: white;--ttBorderColor: ${color};${checkWarningOrError.getAttribute(
      'tt-style',
    )};top: ${top}px;left: ${left}px;`
  } else {
    tooltip.style = `visibility:hidden;--ttBackgroundColor: white;--ttBorderColor: rgb(var(--colors-accent));${targetWithTT.getAttribute(
      'tt-style',
    )};top: ${top}px;left: ${left}px;`
  }
  tooltip.innerHTML = `<div>${insane(text, { allowedTags: ['div', 'span', 'html', 'br'] })}</div>`
  tooltip.target = targetWithTT
  const outOFWindow = (tooltip.getBoundingClientRect().width || 0) + left - window.innerWidth
  if (outOFWindow > 0) {
    tooltip.style.cssText += `left:${left - outOFWindow - 5}px;--ttPositionIndicator:${outOFWindow}px;`
  }
  tooltip.style.visibility = 'visible'
  targetWithTT.onmouseleave = () =>
    (targetWithTT.onmouseleave = tooltip.target = tooltip.style = tooltip.className = tooltip.innerHTML = '')
})
