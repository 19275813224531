<template>
  <template v-if="props.options.showLabel">
    <nx-pie-label :data="props.data" :options="props.options"></nx-pie-label>
  </template>
  <div
    v-else
    class="nx-pie relative m-auto"
    :class="props?.options?.variant === 'pie' ? 'variant-pie' : 'variant-default'"
  >
    <svg viewBox="-100 -100 200 200" class="m-auto max-h-full">
      <path :d="item.arc" :class="item[options.x]?.format('dash')" v-for="item in items" :style="item.style"></path>
      <circle r="65"></circle>
    </svg>
  </div>
</template>
<script setup>
import * as d3 from 'd3'
import { computed } from 'vue'
const props = defineProps(['data', 'options'])
const total = computed(() => props.data.map(v => v[props.options.y]).sum())
const items = computed(() => {
  return props.data.map((v, i, ds) => {
    const prev = ds.slice(0, i).map(props.options.y).sum()
    const next = prev + v[props.options.y]
    return {
      ...v,
      arc: d3.arc()({
        innerRadius: props.options.variant === 'pie' ? 0 : 60,
        outerRadius: 90,
        startAngle: 2 * Math.PI * (prev / total.value),
        endAngle: 2 * Math.PI * (next / total.value),
      }),
      style: {
        fill: props.options.palette[i],
      },
    }
  })
})
</script>
<style scoped>
.nx-pie path {
  cursor: pointer;
  /* fill: rgb(var(--primary)); */
}
.nx-pie circle {
  pointer-events: none;
  fill: none;
  stroke: white;
  stroke-width: 2px;
}
.nx-pie.variant-pie path {
  stroke: white;
  stroke-width: 2px;
}
.nx-pie.variant-pie circle {
  stroke: none;
  stroke-width: 0px;
}
</style>
