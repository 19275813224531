<template>
  <transition @leave="leave">
    <loader v-if="isLoading" />
  </transition>

  <h1 v-if="$root.$route.query?.name">{{ t.excel_template_edit_title }} {{ $root.$route.query.name }}</h1>
  <h1 v-else>{{ t.new_excel_template }}</h1>
  <div class="subtitle">{{ t['client-edition-workflow-subtitle'] }}</div>
  <!-- Template creation / edition form -->
  <div class="block">
    <div class="row">
      <!-- Left column-->
      <div class="column expand">
        <!-- Template name -->
        <div class="row">
          <label>
            {{ t.template_name }}
            <div class="mt-2">
              <input
                id="template-name"
                type="text"
                class="expand"
                v-model="templateName"
                :disabled="$root.$route.query?.name"
                :style="{ border: isValidName ? 'unset' : '1px solid red' }"
                @input="onNameChanged"
              />
            </div>
          </label>
        </div>
        <div class="row mt-2" v-show="!isValidName">
          <span class="alert">{{ t.template_exists }}</span>
        </div>
        <!-- Associated data report -->
        <template v-if="dataReportList && dataReportList.length > 0">
          <div class="row mt-2">
            <label for="data-report-list">
              {{ t.associated_data_report }}
              <div class="mt-2">
                <select
                  id="data-report-list"
                  class="expand"
                  v-model="dataReportId"
                  @change="onDrChanged"
                  :disabled="$root.$route.query?.name"
                >
                  <option v-for="dr in dataReportList" :value="dr.id">
                    {{ dr.name }}
                  </option>
                </select>
              </div>
            </label>
          </div>
        </template>
        <!-- Template variables -->
        <div class="row mt-2">
          <label for="template-variables">
            {{ t.template_variables }}
            <div class="mt-2" v-if="dataReport">
              <div class="expand">
                <code-editor
                  id="template-variables"
                  :code="templateVariables"
                  language="json"
                  @update="onVariablesChanged"
                />
              </div>
            </div>
          </label>
        </div>
        <div class="row mt-2" v-show="!jsonParsedString(templateVariables)">
          <span class="alert">{{ t.invalid_json }}</span>
        </div>
        <!-- Associated DQC report -->
        <template v-if="dataReportId">
          <div class="row mt-2">
            <label for="data-report-list">
              {{ t.associated_dqc }}
              <div class="mt-2">
                <select id="data-report-list" class="expand" v-model="dqcReportId" @change="onDqcChanged">
                  <option :value="null">-</option>
                  <option v-for="dqc in filteredDQCReport" :value="dqc.id">
                    {{ dqc.name }}
                  </option>
                </select>
              </div>
            </label>
          </div>
        </template>
      </div>
      <!-- Right column-->
      <div class="column expand ml-2">
        <div class="row">
          <div class="column expand ml-2">
            <label for="data-report-query">{{ t.data_report_query }}</label>
          </div>
        </div>
        <div class="row expand mt-2" v-if="dataReport">
          <div class="column expand ml-2">
            <code-editor
              id="data-report-query"
              max-height="310px"
              readonly
              :code="dataReport?.query"
              language="graphql"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- Form buttons -->
    <div class="row mt-4">
      <button @click="$router.push({ path: $root.appath + 'templates' })">{{ t.cancel }}</button>
      <button
        @click="save"
        :disabled="
          !isModified ||
          !isValidName ||
          !jsonParsedString(templateVariables) ||
          isSaving ||
          !templateName ||
          templateName.trim() === '' ||
          !dataReportId
        "
        class="primary ml-2"
      >
        {{ t.save }}
      </button>
    </div>
  </div>

  <!-- Template history table and upload button -->
  <div class="expand block" v-if="templateHistory && templateHistory.length > 0">
    <label>{{ t.template_history }}</label>
    <div style="max-height: calc(100vh - 550px); overflow: auto">
      <spreadsheet
        class="stripped expand nosort"
        :data="templateHistory"
        :options="{
          columns: ['id', 'createdAt', 'user', 'variables', 'dataQualityCheckReportId', 'actions'],
          editable: false,
        }"
      >
        <template v-slot:cell-id="{ line }">
          {{ line.id }}
        </template>
        <template v-slot:cell-created-at="{ line }">
          {{ line.createdAt.split('T')[0] }}
        </template>
        <template v-slot:cell-user="{ line }">
          {{ line.user }}
        </template>
        <template v-slot:cell-variables="{ line }">
          {{ line.variables }}
        </template>
        <template v-slot:header-data-quality-check-report-id>
          <div>Data Quality Report</div>
        </template>
        <template v-slot:cell-data-quality-check-report-id="{ line }">
          {{ dqcNameById(line.dataQualityCheckReportId) || '-' }}
        </template>
        <template v-slot:cell-actions="{ line }">
          <div class="row">
            <button class="ghost" tt="Download" @click="downloadExcel(line)">
              <svg-icon name="pt-icon-cloud-download"></svg-icon>
            </button>
            <button v-if="!generatingReports.includes(line.id)" class="ghost" tt="Report" @click="reportExcel(line)">
              <svg-icon name="pt-icon-play"></svg-icon>
            </button>
            <span v-else>{{ t.excel_in_progress }}...</span>
          </div>
        </template>
      </spreadsheet>
    </div>
    <div class="row mt-4">
      <button class="primary" @click="document.getElementById('excel-file-input').click()">
        {{ t.upload_new_version }}
      </button>
      <input id="excel-file-input" style="display: none" type="file" @change="onFileChanged($event)" accept=".xlsx" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import dataReportService from '@100-m/hauru/src/services/DataReportService'
import dqcReportService from '@100-m/hauru/src/services/DataQualityReportService'
import templateService from '@100-m/hauru/src/services/TemplateService'
import excelTemplateService from '@100-m/hauru/src/services/ExcelTemplateService'
import { downloadResponseAsFile } from '@100-m/hauru/src/services/RestService'

const isLoading = ref(true)
const isSaving = ref(false)
const isModified = ref(false)
const dataReportList = ref([]) // the list of available data reports
const dqcList = ref([]) // the list of available dqc reports
const templateName = ref() // the name of the current template
const templateHistory = ref([]) // the template history (i.e. versions) of current template
const dataReportId = ref() // the id of the selected data report associated with current template
const dqcReportId = ref() // the id of the selected dqc report associated with selected data report
const templateVariables = ref('{}') // the variables of the current template (as a String)
const initialTemplateVariables = ref()
const dataReport = ref()
const takenNames = ref([])
const isValidName = ref(true)
const generatingReports = ref([]) // an array of line.id

onMounted(async () => {
  dataReportList.value = (await dataReportService.list())
    .filter(
      dr => !isNaN(parseInt(dr.id)), // exclude builtin data reports which have an alphanumeric id
    )
    .sort((a, b) => a.name.localeCompare(b.name))
  dqcList.value = await dqcReportService.all()
  if ($root.$route.query?.name) {
    // View / Edit an existing template
    templateName.value = $root.$route.query.name
    getHistory().then(templates => {
      dataReportId.value = templates[0].dataReportId
      dqcReportId.value = templates[0].dataQualityCheckReportId
      initialTemplateVariables.value = JSON.stringify(templates[0].variables || {}) // TODO add .variables to the API
      templateVariables.value = initialTemplateVariables.value
      setDataReport()
    })
  } else {
    // Create a new template
    // We need to know the existing names to check if the new name is available:
    const templates = await templateService.findMany()
    takenNames.value = templates.map(t => t.name)
  }
  isLoading.value = false
})

const setDataReport = () => {
  dataReport.value = (dataReportList.value || []).find(dr => dr.id === dataReportId.value)
}

const filteredDQCReport = computed(() => {
  if (!dataReportId.value) return []
  return dqcList.value.filter(dqc => String(dqc.dataReport?.id) === String(dataReportId.value))
})

const downloadExcel = async line => {
  const response = await excelTemplateService.download(line.id)
  downloadResponseAsFile(response, 'application/vnd.ms-excel', `${line.name}-${line.id}.xlsx`)
}

const reportExcel = async line => {
  const reportFilename = `${line.name}-${line.id}-report.xlsx`
  generatingReports.value.push(line.id)
  const response = await excelTemplateService.report(line.id, line.dataReportId, line.variables, reportFilename)
  generatingReports.value = generatingReports.value.filter(x => x !== line.id)
  downloadResponseAsFile(response, 'application/vnd.ms-excel', reportFilename)
}

const jsonParsedString = str => {
  if (!str || (str && str.trim() === '')) return {}
  let jsonResult
  try {
    jsonResult = JSON.parse(str)
  } catch (e) {}
  return jsonResult
}

const getHistory = () => {
  return templateService.findManyByName({ name: templateName.value, limit: 50 }).then(templates => {
    templateHistory.value = templates.map(t => ({
      ...t,
      variables: t.layout.variables || {},
    }))
    // console.log('getHistory', templateHistory.value)
    return templateHistory.value
  })
}

const save = () => {
  isSaving.value = true
  const variables = jsonParsedString(templateVariables.value)
  const finalize = response => {
    getHistory().then(templates => {
      const tpl = templates[0] // the latest version of the template
      // NOTE: If another user also creates a template with the same name at exactly the same time,
      //       the latest id we get here might not be the one the present user just created!
      //       Very unlikely, but not impossible.
      $root.$router.push({ query: { name: templateName.value } })
      // now we have the template id that has just been created, we use it to name the incoming file:
      downloadResponseAsFile(response, 'application/vnd.ms-excel', `${templateName.value}-${tpl.id}.xlsx`)
      isSaving.value = false
    })
  }
  if ($root.$route.query?.name) {
    // Edit an existing template (i.e.create a new version of it from its latest version)
    const latestTemplate = templateHistory.value[0]
    excelTemplateService.update(latestTemplate.id, dataReportId.value, variables, dqcReportId.value).then(finalize)
  } else {
    // Create a new template
    excelTemplateService.create(templateName.value, dataReportId.value, variables, dqcReportId.value).then(finalize)
  }
}

const createBase64File = (f, callback) => {
  const reader = new FileReader()
  reader.onload = event => {
    callback(event.target.result.split(',')[1])
  }
  reader.readAsDataURL(f)
}

const onNameChanged = () => {
  isModified.value = true
  isValidName.value = !takenNames.value.includes(templateName.value)
}

const onDrChanged = () => {
  isModified.value = true
  setDataReport()
  dqcReportId.value = null
}

const onDqcChanged = () => {
  isModified.value = true
}

const onVariablesChanged = e => {
  templateVariables.value = e
  isModified.value = initialTemplateVariables.value !== templateVariables.value
}

const onFileChanged = event => {
  const file = event.target.files[0]
  createBase64File(file, base64 => {
    const templateId = templateHistory.value[0].id
    excelTemplateService.upload(templateId, dataReportId.value, dqcReportId.value, base64).then(response => {
      console.log('Uploaded', response)
      getHistory()
    })
  })
}

const dqcNameById = id => {
  const dqc = dqcList.value.find(x => x.id === id)
  return dqc ? dqc.name : null
}
</script>

<style scoped>
.expand > *,
.expand input,
.expand select {
  min-width: 100%;
}

label {
  padding-top: 8px;
}

span.alert {
  color: red;
  font-size: 12px;
  font-weight: normal;
}

.mt-2 {
  margin-top: 8px;
}

.mt-4 {
  margin-top: 16px;
}

.ml-2 {
  margin-left: 8px;
}
</style>
